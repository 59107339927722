import React from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/smile-again.scss"

const SmileAgainProgram = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    speed: 2000
  }
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at  Oral Surgery & Dental Implant Specialists of Cincinnati."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image"
            alt="smile again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image-mobile"
            alt="smile again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Smile Again</h1>

            <p>
              The Oral Surgery & Dental Implant Specialists of Cincinnati team
              is proud to announce that this year’s Smile Again recipient is
              Lauren!
            </p>

            <p>
              Lauren, 57, is a disabled woman who has faced immense personal and
              health challenges. After losing her husband suddenly in 2014, just
              months after completing chemotherapy for colon cancer, she raised
              her two daughters on a fixed income. Her health worsened when
              cancer returned in 2020, and the chemotherapy damaged her teeth,
              leaving her with pain and discomfort. Though she received
              dentures, they do not fit properly and cause daily struggles.
            </p>

            <p>
              Lauren believes restoring her smile would help her regain
              confidence and comfort in everyday life. We hope that providing
              Lauren with the dental care she needs will improve her self-esteem
              and make her daily life easier, allowing her to continue being the
              strong mother she’s always been.
            </p>

            <h2 style={{ fontSize: "24px" }}>Full-Arch Restoration</h2>
            <p>
              Lauren will receive a full set of customized, artificial teeth at
              no cost. This tooth replacement solution is called full-arch
              restoration because it replaces a full dental arch of missing or
              failing teeth. Our team of experts will work closely with a
              restorative dentist to complete the treatment.
            </p>
          </div>
        </div>

        {/* <div className="sas__section">
          <div className="sas__container small">
            <Slider {...settings}>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-1"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-2"
                  alt="program recipient"
                />
              </div>
              <div>
                <img
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/CIOS/DEV/smile-again-recipient-3"
                  alt="program recipient"
                />
              </div>
              <div>
                <video
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    marginInline: "auto",
                    display: "block"
                  }}
                  controls>
                  <source
                    src="https://res.cloudinary.com/nuvolum/video/upload/v1699992734/CIOS/DEV/smile-again-recipient-4.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Slider>
          </div>
        </div> */}

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/cincinnatioralsurgeons"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/cincinnatioralsurgeons/?hl=en"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              to follow Lauren’s journey to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-cincinnati-oh/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
